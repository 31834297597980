import * as React from "react";

export default function Paragraph({ children, disableMarginTop }) {
  return (
    <p
      className={`${
        disableMarginTop ? "" : "mt-8 "
      }mb-8 text-xl text-gray-500 leading-8`}
    >
      {children}
    </p>
  );
}
