import * as React from "react";
import PageBody from "../components/PageBody";
import H2 from "../components/Headings/H2";
import Paragraph from "../components/Paragraph";
import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function PrivacyPolicyPage() {
  const seo = createSeoData({
    title: "Privacy Policy",
    description:
      "Check out Lingua Fonica's privacy policy to learn how we use your data.",
  });

  const hero = {
    heroTitleLine1: "Privacy",
    heroTitleLine2: "Policy",
    heroContent: "Details",
  };

  return (
    <Layout seo={seo} hero={hero}>
      <PageBody limitMaxWidth>
        <H2 subtitle="1. Introduction" subtitleAlignLeft />
        <Paragraph>
          Lingua Fonica (“we” or “us” or “our”) respects the privacy of our
          users (“user” or “you”). This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you visit our
          website (linguafonica.com) and our mobile application, including any
          other media form, media channel, mobile website, or mobile application
          related or connected thereto (collectively, the “Site”). Please read
          this privacy policy carefully. If you do not agree with the terms of
          this privacy policy, please do not access the site. We reserve the
          right to make changes to this Privacy Policy at any time and for any
          reason. We will alert you about any changes by updating the “Last
          Updated” date of this Privacy Policy. Any changes or modifications
          will be effective immediately upon posting the updated Privacy Policy
          on the Site, and you waive the right to receive specific notice of
          each such change or modification. You are encouraged to periodically
          review this Privacy Policy to stay informed of updates. You will be
          deemed to have been made aware of, will be subject to, and will be
          deemed to have accepted the changes in any revised Privacy Policy by
          your continued use of the Site after the date such revised Privacy
          Policy is posted. This privacy policy was created using Termly
        </Paragraph>
        <H2 subtitle="2. Collection of Your Information" subtitleAlignLeft />
        <Paragraph>
          We may collect information about you in a variety of ways. The
          information we may collect on the Site includes:
        </Paragraph>
        <H2 subtitle="Personal Data:" />
        <Paragraph>
          Personally identifiable information, such as your name, billing
          address, and email address, that you voluntarily give to us when you
          register with the Site, or when you choose to participate in various
          activities related to the Site, such as ordering services, using our
          online chat video, or voluntarily completing customer surveys or
          providing feedback via email or other communications. You are under no
          obligation to provide us with personal information of any kind,
          however your refusal to do so may prevent you from using certain
          features of the Site.
        </Paragraph>
        <H2 subtitle="Derivative Data:" />
        <Paragraph>
          Information our servers automatically collect when you access the
          Site, such as your IP address, your browser type, your operating
          system, your access times, and the pages you have viewed directly
          before and after accessing the Site. This information may also include
          your device name and type, your operating system, your phone number,
          your country, your likes and replies to a post, and other interactions
          with the application and other users via server log files, as well as
          any other information you choose to provide.
        </Paragraph>
        <H2 subtitle="Financial Data:" />
        <Paragraph>
          Financial information, such as data related to your payment method
          (e.g. valid credit card number, card brand, expiration date) that we
          may collect when you purchase, order, return, exchange, or request
          information about our services from the Site or our mobile
          application. We store only very limited, if any, financial information
          that we collect. Otherwise, all financial information is stored by our
          payment processor,{" "}
          <a
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
            href="https://stripe.com/privacy"
          >
            Stripe
          </a>
          , and you are encouraged to review their privacy policy and contact
          them directly for responses to your questions.
        </Paragraph>
        <H2 subtitle="Data From Contests, Giveaways, and Surveys:" />
        <Paragraph>
          Personal and other information you may provide when entering contests
          or giveaways and/or responding to surveys.
        </Paragraph>
        <H2 subtitle="Geo-Location Information:" />
        <Paragraph>
          We may request access or permission to and track location-based
          information from your mobile device, either continuously or while you
          are using our mobile application, to provide location-based services.
          If you wish to change our access or permissions, you may do so in your
          device’s settings
        </Paragraph>
        <H2 subtitle="Email and Push Notifications:" />
        <Paragraph>
          We may request to send you email or push notifications regarding your
          account. If you wish to opt-out from receiving these types of
          communications, you may turn off push notifications or opt-out of
          emails through the application.
        </Paragraph>
        <H2 subtitle="3. Use of Your Information" subtitleAlignLeft />
        <Paragraph>
          Having accurate information about you permits us to provide you with a
          smooth, efficient, and customized experience. Specifically, we may use
          information collected about you via the Site to:
          <li>Create and manage your account</li>
          <li>
            Generate a personal profile about you to make future visits to the
            Site more personalized.
          </li>
          <li>Email you regarding your account or order.</li>
          <li>Enable user-to-user communications.</li>
          <li>
            Fulfill and manage purchases, orders, payments, and other
            transactions related to the Site.
          </li>
          <li>Process payments and refunds.</li>
          <li>Perform other business activities as needed.</li>
          <li>
            Increase the efficiency and operation of the Site and our mobile
            application.
          </li>
          <li>Respond to product and customer service requests.</li>
          <li>Notify you of updates to the Site.</li>
          <li>
            Offer new products, services, mobile application, and/or
            recommendations to you.
          </li>
          <li>Resolve disputes and troubleshoot problems.</li>
          <li>Send you a newsletter.</li>
          <li>Administer sweepstakes, promotions, and contests.</li>
          <li>
            Deliver targeted advertising, coupons, newsletters, and other
            information regarding promotions and the Site to you.
          </li>
          <li>
            Prevent fraudulent transactions, monitor against theft, and protect
            against criminal activity.
          </li>
          <li>Assist law enforcement and respond to subpoena.</li>
          <li>
            Compile anonymous statistical data and analysis for use internally
            or with third parties.
          </li>
          <li>
            Monitor and analyze usage and trends to improve your experience with
            the Site and our mobile application.
          </li>
          <li>
            Request feedback and contact you about your use of the Site and our
            mobile application.
          </li>
          <li>Solicit support for the Site and our mobile application.</li>
          If you have agreed to receive marketing, you may always opt out at a
          later date. You have the right at any time to stop Our Company from
          contacting you for marketing purposes or giving your data to other
          members of the Our Company Group. If you no longer wish to be
          contacted for marketing purposes, please click here.
        </Paragraph>
        <H2 subtitle="4. Disclosure of Your Information" subtitleAlignLeft />
        <Paragraph>
          We may share information we have collected about you in certain
          situations. Your information may be disclosed as follows:
        </Paragraph>
        <H2 subtitle="By Law or to Protect Rights" />
        <Paragraph>
          If we believe the release of information about you is necessary to
          respond to legal process, to investigate or remedy potential
          violations of our policies, or to protect the rights, property, and
          safety of others, we may share your information as permitted or
          required by any applicable law, rule, or regulation. This includes
          exchanging information with other entities for fraud protection and
          credit risk reduction.
        </Paragraph>
        <H2 subtitle="Third-Party Service Providers" />
        <Paragraph>
          We may share your information with third parties that perform services
          for us or on our behalf, including payment processing, data analysis,
          email delivery, hosting services, customer service, and marketing
          assistance.
        </Paragraph>
        <H2 subtitle="Interactions with Other Users" />
        <Paragraph>
          If you interact with other users of the Site, those users may see your
          name, profile photo, and descriptions of your activity, including
          sending invitations to other users, or chatting with other users. Our
          chat service is not end-to-end encrypted.
        </Paragraph>
        <H2 subtitle="Online Postings" />
        <Paragraph>
          When you post comments, contributions or other content to the Site,
          your posts may be viewed by all users and may be publicly distributed
          outside the Site in perpetuity.
        </Paragraph>
        <H2 subtitle="Sale or Bankruptcy" />
        <Paragraph>
          If we reorganize or sell all or a portion of our assets, undergo a
          merger, or are acquired by another entity, we may transfer your
          information to the successor entity. If we go out of business or enter
          bankruptcy, your information would be an asset transferred or acquired
          by a third party. You acknowledge that such transfers may occur and
          that the transferee may decline to honor commitments we made in this
          Privacy Policy. We are not responsible for the actions of third
          parties with whom you share personal or sensitive data, and we have no
          authority to manage or control third-party solicitations. If you no
          longer wish to receive correspondence, emails or other communications
          from third parties, you are responsible for contacting the third party
          directly
        </Paragraph>
        <H2 subtitle="5. Tracking Technologies" subtitleAlignLeft />
        <H2 subtitle="Cookies and Web Beacons" />
        <Paragraph>
          Cookies are text files placed on your computer to collect standard
          Internet log information and visitor behavior information. When you
          visit our websites, we may collect information from you automatically
          through cookies or similar technology. You can set your browser not to
          accept cookies. However, in a few cases, some of our website features
          may not function as a result. For further information, including how
          to remove cookies from your browser, visit{" "}
          <a
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
            href="http://allaboutcookies.org"
          >
            allaboutcookies.org
          </a>
          . We may use cookies, web beacons, tracking pixels, and other tracking
          technologies on the Site and our mobile application to help customize
          the Site and our mobile application and improve your experience. When
          you access the Site, your personal information is not collected
          through the use of tracking technology. Most browsers are set to
          accept cookies by default. You can remove or reject cookies, but be
          aware that such action could affect the availability and functionality
          of the Site. You may not decline web beacons. However, they can be
          rendered ineffective by declining all cookies or by modifying your web
          browser’s settings to notify you each time a cookie is tendered,
          permitting you to accept or decline cookies on an individual basis. We
          may use cookies, web beacons, tracking pixels, and other tracking
          technologies on the Site to help customize the Site and improve your
          experience. By using the Site, you agree to be bound by the cookie
          policies described here. We use cookies to keep you signed in and
          understand how you use our website. Our Company uses these cookies so
          that we recognize you on our website and remember your previously
          selected preferences. These could include what language you prefer and
          location you are in. A mix of first-party and third-party cookies are
          used on the Site.
        </Paragraph>
        <H2 subtitle="Website Analytics" />
        <Paragraph>
          We may also partner with selected third-party vendors, such as Google
          Analytics, Google Tag Manager, Cloudflare and Hotjar, to allow
          tracking technologies and remarketing services on the Site through the
          use of first party cookies and third-party cookies, to, among other
          things, analyze and track users’ use of the Siate, determine the
          popularity of certain content and better understand online activity.
          By accessing the site, you consent to the collection and use of your
          information by these third-party vendors. You are encouraged to review
          their privacy policy and contact them directly for responses to your
          questions. We do not transfer personal information to these
          third-party vendors. However, if you do not want any information to be
          collected and used by tracking technologies, you can visit the
          third-party vendor or the Network Advertising Initiative Opt-Out Tool
          (
          <a
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
            href="https://optout.networkadvertising.org"
          >
            https://optout.networkadvertising.org
          </a>
          ) or Digital Advertising Alliance Opt-Out Tool (
          <a
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
            href="https://optout.aboutads.info"
          >
            https://optout.aboutads.info
          </a>
          ). You should be aware that getting a new computer, installing a new
          browser, upgrading an existing browser, or erasing or otherwise
          altering your browser’s cookies files may also clear certain opt-out
          cookies, plug-ins, or settings.
        </Paragraph>
        <H2 subtitle="6. Third-Party Websites" subtitleAlignLeft />
        <Paragraph>
          The Site and our mobile application may contain links to third-party
          websites and applications of interest, including advertisements and
          external services, that are not affiliated with us. Once you have used
          these links to leave the Site or our mobile application, any
          information you provide to these third parties is not covered by this
          Privacy Policy, and we cannot guarantee the safety and privacy of your
          information. Before visiting and providing any information to any
          third-party websites, you should inform yourself of the privacy
          policies and practices (if any) of the third party responsible for
          that website, and should take those steps necessary to, in your
          discretion, protect the privacy of your information. We are not
          responsible for the content or privacy and security practices and
          policies of any third parties, including other sites, services or
          applications that may be linked to or from the Site or our mobile
          application.
        </Paragraph>
        <H2 subtitle="7. Security of Your Information" subtitleAlignLeft />
        <Paragraph>
          We use administrative, technical, and physical security measures to
          help protect your personal information. While we have taken reasonable
          steps to secure the personal information you provide to us, please be
          aware that despite our efforts, no security measures are perfect or
          impenetrable, and no method of data transmission can be guaranteed
          against any interception or other type of misuse. Any information
          disclosed online is vulnerable to interception and misuse by
          unauthorized parties. Therefore, we cannot guarantee complete security
          if you provide personal information.
        </Paragraph>
        <H2 subtitle="8. Policy for Children" subtitleAlignLeft />
        <Paragraph>
          We do not knowingly solicit information from or market to children
          under the age of 13. If you become aware of any data we have collected
          from children under age 13, please contact us using the contact
          information provided below.
        </Paragraph>
        <H2
          subtitle="9. Controls for Do-Not-Track Features"
          subtitleAlignLeft
        />
        <Paragraph>
          Most web browsers and some mobile operating systems include a
          Do-Not-Track (“DNT”) feature or setting you can activate to signal
          your privacy preference not to have data about your online browsing
          activities monitored and collected. No uniform technology standard for
          recognizing and implementing DNT signals has been finalized. As such,
          we do not currently respond to DNT browser signals or any other
          mechanism that automatically communicates your choice not to be
          tracked online. If a standard for online tracking is adopted that we
          must follow in the future, we will inform you about that practice in a
          revised version of this Privacy Policy
        </Paragraph>
        <H2
          subtitle="10. Options Regarding Your Information"
          subtitleAlignLeft
        />
        <H2 subtitle="Account Information" />
        <Paragraph>
          You may at any time review or change the information in your account
          or terminate your account by contacting us using the contact
          information provided below. Upon your request to terminate your
          account, we will deactivate or delete your account and information
          from our active databases. However, some information may be retained
          in our files to prevent fraud, troubleshoot problems, assist with any
          investigations, enforce our Terms of Use and/or comply with legal
          requirements.
        </Paragraph>
        <H2 subtitle="Emails and Communications" />
        <Paragraph>
          If you no longer wish to receive correspondence, emails, or other
          communications from us, you may opt-out by contacting us using the
          contact information provided below. If you no longer wish to receive
          correspondence, emails, or other communications from third parties,
          you are responsible for contacting the third party directly.
        </Paragraph>
        <H2 subtitle="11. California Privacy Rights" subtitleAlignLeft />
        <Paragraph>
          California Civil Code Section 1798.83, also known as the “Shine The
          Light” law, permits our users who are California residents to request
          and obtain from us, once a year and free of charge, information about
          categories of personal information (if any) we disclosed to third
          parties for direct marketing purposes and the names and addresses of
          all third parties with which we shared personal information in the
          immediately preceding calendar year. If you are a California resident
          and would like to make such a request, please submit your request in
          writing to us using the contact information provided below. If you are
          under 18 years of age, reside in California, and have a registered
          account with the Site, you have the right to request removal of
          unwanted data that you publicly post on the Site. To request removal
          of such data, please contact us using the contact information provided
          below, and include the email address associated with your account and
          a statement that you reside in California. We will make sure the data
          is not publicly displayed on the Site, but please be aware that the
          data may not be completely or comprehensively removed from our
          systems.
        </Paragraph>
        <H2 subtitle="12. GDPR" subtitleAlignLeft />
        <Paragraph>
          Our users in the UK and the EU have data protection rights as follows
          under GDPR:
          <li>
            The right to access - You have the right to request copies of your
            personal data. We may charge you a small fee for this service.
          </li>
          <li>
            The right to rectification - You have the right to request that we
            correct any information you believe is inaccurate. You also have the
            right to request us to complete information you believe is
            incomplete.
          </li>
          <li>
            The right to erasure - You have the right to request that we erase
            your personal data, under certain conditions.
          </li>
          <li>
            The right to restrict processing - You have the right to request
            that we restrict the processing of your personal data, under certain
            conditions.
          </li>
          <li>
            The right to object to processing - You have the right to object to
            our processing of your personal data, under certain conditions.
          </li>
          <li>
            The right to data portability - You have the right to request that
            we transfer the data that we have collected to another organization,
            or directly to you, under certain conditions.
          </li>
          <li>
            Right to be informed - organisations must tell individuals what data
            of theirs is being collected, how it’s being used, how long it will
            be kept and whether it will be shared with any third parties.
          </li>
          <li>
            Rights related to automated decision making, including profiling -
            individuals can ask organisations to provide a copy of its automated
            processing activities if they believe the data is being processed
            unlawfully. You should also remind individuals that they are free to
            exercise their rights and explain how they can do this.
          </li>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at our
          email: info@linguafonica.com. Should you wish to report a complaint or
          if you feel that Our Company has not addressed your concern in a
          satisfactory manner, you may contact the Information
          Commissioner&apos;s Office.
        </Paragraph>
        <H2 subtitle="13. How we store your data" subtitleAlignLeft />
        <Paragraph>
          Our Company securely stores your data on our cloud system with Digital
          Ocean, who have a secure system with more details on their privacy
          policy{" "}
          <a
            className="underline font-medium text-indigo-600 hover:text-indigo-500"
            href="https://www.digitalocean.com/legal/privacy-policy/"
          >
            here
          </a>
          . Our Company will keep your personally identifiable data for six
          years. Once this time period has expired, we will delete your data by
          deleting your personally identifiable data.
        </Paragraph>
        <H2 subtitle="14. Contact Us" subtitleAlignLeft />
        <Paragraph>
          If you have questions or comments about this Privacy Policy, the data
          we hold on you, or you would like to exercise one of your data
          protection rights, please contact us at: Lingua Fonica, LLC,
          info@linguafonica.com
        </Paragraph>
      </PageBody>
    </Layout>
  );
}
